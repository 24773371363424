<template>
  <div id="wrapper">
    <div class="container-fluid error">
      <div class="row">
        <div class="col">
          <div class="error-wrapper text-center">
            <img class="h3-logo"
                 src="../assets/images/h3_logo.png"
                 alt="logo"
                 @click="goHome" />
            <h3 class="heading"> {{ heading }} </h3>
            <p class="small"> {{ text }} </p>
            <b-button class="btn btn-h3-small home-btn"
                    @click="goHome"
                    variant="outline-primary">
              Go to Home page
            </b-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name : 'Error',
    data() {
      return {
        heading : null,
        text    : null,
      }
    },
    methods : {

      /**
       * Go to Home Page
       */
      goHome() {
        this.$router.push('/');
      },
    },
    mounted() {
      if (this.$route.path === '/expired-link') {
        this.heading = 'UH-OH! LOOKS LIKE THIS LINK HAS ALREADY EXPIRED';
        this.text = 'This URL may have already been used or timed-out';
      }
    },
  }
</script>

<style lang="scss" scoped>
  @import '../assets/scss/components/error';
</style>